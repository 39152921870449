<template>
  <div>
    <div class="wrap-slick">
      <swiper class="style-swiper" v-if="listPost.length" :options="swiperOption">
        <swiper-slide class="wrap-slide" v-for="(item, i) in listPost1" :key="i">
          <div class="slide-item po-relative">
            <div>
              <img class="image-banner" :src="item.avatar" alt="" />
            </div>
            <div class="teammate-name">{{ item.fullName }}</div>
            <div class="teammate-des">{{ item.title }}</div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="wrap-slick style-slick-bottom">
      <swiper class="style-swiper" v-if="listPost.length" :options="swiperOption" dir="rtl">
        <swiper-slide class="wrap-slide" v-for="(item, i) in listPost2" :key="i">
          <div class="slide-item po-relative">
            <div>
              <img class="image-banner" :src="item.avatar" alt="" />
            </div>
            <div class="teammate-name">{{ item.fullName }}</div>
            <div class="teammate-des">{{ item.title }}</div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
  import defaultProject from '../utils/firebase'

  import 'swiper/swiper-bundle.css'
  import Vue from 'vue'
  import { Swiper as SwiperClass, Pagination, Autoplay } from 'swiper/swiper.esm'
  import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
  SwiperClass.use([Pagination, Autoplay])
  Vue.use(getAwesomeSwiper(SwiperClass))
  const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass)
  export default {
    components: { Swiper, SwiperSlide },
    data() {
      return {
        listPost: [],
        swiperOption: {
          slidesPerView: 2,
          spaceBetween: 20,
          loop: true,
          autoplay: {
            delay: 2500,
            disableOnInteraction: false
          },
          centeredSlides: true,
          grabCursor: true,
          breakpoints: {
            350: {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: true,
              grabCursor: true
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
              centeredSlides: true,
              grabCursor: true
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20
            },
            1000: {
              slidesPerView: 3,
              spaceBetween: 5
            },
            1300: {
              slidesPerView: 4,
              spaceBetween: 5
            },
            1400: {
              slidesPerView: 5,
              spaceBetween: 5
            },
            1770: {
              slidesPerView: 6,
              spaceBetween: 5
            }
          }
        },

        listPost1: [],
        listPost2: []
      }
    },
    async mounted() {
      await this.getMyStory()
    },
    methods: {
      async getMyStory() {
        defaultProject
          .firestore()
          .collection('members')
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              this.listPost.push(doc.data())
            })
            if (this.listPost.length >= 2) {
              const length = parseInt(this.listPost.length / 2)
              console.log(length), length

              this.listPost1 = this.listPost.slice(0, length)
              this.listPost2 = this.listPost.slice(length, this.listPost.length)
            }
          })
          .catch(() => {
            this.getMyStory()
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .imageTest {
    background-image: url('../assets/images/backgound-test-1.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .imageTest2 {
    background-image: url('../assets/images/bg-yellow.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .style-swiper {
    display: flex;
  }
  .style-slick-bottom {
    margin-top: 42px;
  }
  .image-banner {
    height: 100%;
    transition: 0.5s;
    pointer-events: none;
    object-fit: contain;
  }
  .slide-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .teammate-name {
      font-size: 2.4rem;
      line-height: 3.6rem;
      font-weight: 400;
      color: #414c63;
      margin-top: 2.9rem;
      @media (max-width: 739px) {
        font-size: 1.8rem;
        margin-top: 0;
      }
    }
    .teammate-des {
      font-size: 1.6rem;
      line-height: 1.9rem;
      font-weight: 400;
      color: #c4c4c4;

      @media (max-width: 739px) {
        font-size: 1.4rem;
      }
    }
  }
</style>
